import ResponsiveSSR from '@pretto/bricks/components/utility/ResponsiveSSR'
import { BlocErrorMain } from '@pretto/bricks/website/calculator2/components/BlocError/Main'
import { ResultCapacityMain } from '@pretto/bricks/website/calculator2/components/Result/Capacity/Main'
import { ResultPurchaseMain } from '@pretto/bricks/website/calculator2/components/Result/Purchase/Main'
import { ResultRenegotiationMain } from '@pretto/bricks/website/calculator2/components/Result/Renegotiation/Main'

import {
  SimulatorRowProps,
  SimulatorType,
} from '@pretto/website/src/templates/blocs/components/simulatorRow/SimulatorRow.types'
import { SimulatorRowInputs } from '@pretto/website/src/templates/blocs/components/simulatorRow/components/SimulatorRowInputs/SimulatorRowInputs'

import React from 'react'

import * as S from './SimulatorRow.styles'

const RESULT = {
  [SimulatorType.CAPACITY]: {
    title: 'Votre capacité',
    card: ResultCapacityMain,
  },
  [SimulatorType.PURCHASE]: {
    title: 'Votre prêt',
    card: ResultPurchaseMain,
  },
  [SimulatorType.RENEGOTIATION]: {
    title: 'Votre nouveau prêt',
    card: ResultRenegotiationMain,
  },
}

export const SimulatorRow: React.FC<SimulatorRowProps> = ({
  errorProps,
  graphProps,
  inputBlocks,
  isGraphActive,
  isError,
  onClickComplete,
  onClickDescriptionButton,
  resultProps,
  title,
  titleTag,
  type,
  ...props
}) => {
  const cardMainProps = isError ? { as: BlocErrorMain, ...errorProps } : { as: RESULT[type].card, ...resultProps }

  return (
    <S.BlockInputs {...props}>
      {title && titleTag && <S.Title $titleTag={titleTag}>{title}</S.Title>}
      <S.WrapperInputs>
        <SimulatorRowInputs inputBlocks={inputBlocks} onClickComplete={onClickComplete} />
        <ResponsiveSSR max="tablet">
          <S.BlocInfo onClickButton={onClickDescriptionButton} projectKind={type} />
        </ResponsiveSSR>

        <S.ResultSection min="tablet">
          <S.ResultSectionTitle>{RESULT[type].title}</S.ResultSectionTitle>

          <S.ResultContainer>
            <S.Result {...cardMainProps} />
          </S.ResultContainer>

          {isGraphActive && <S.Graph {...graphProps} />}
        </S.ResultSection>
      </S.WrapperInputs>
    </S.BlockInputs>
  )
}
