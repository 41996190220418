import { InfoWindowContent } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/components/AgentsMap/InfoWindowContent/InfoWindowContent'
import { FormatedAgentType } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { AdvancedMarker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps'
import { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef } from 'react'

import { InfoWindow } from './InfoWindow'

type AgentMarkerProps = {
  agent: FormatedAgentType
  clusterer: MarkerClusterer | null
  onClose?: () => void
  onOpen?: (ref: MutableRefObject<google.maps.InfoWindow | null>) => void
}

export const AgentMarker = forwardRef(({ agent, clusterer, onClose, onOpen }: AgentMarkerProps, ref) => {
  const [markerRef, marker] = useAdvancedMarkerRef()

  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null)
  const innerRef = useRef<{
    close: () => void
    open: () => void
    toggle: () => void
  } | null>(null)

  useImperativeHandle(
    innerRef,
    (): {
      close: () => void
      key: string
      open: () => void
      toggle: () => void
    } => ({
      close() {
        infoWindowRef.current?.close()
      },
      key: agent.key,
      open() {
        infoWindowRef.current?.open()
      },
      toggle() {
        infoWindowRef.current?.toggle()
      },
    }),
    [agent.key]
  )

  useImperativeHandle(ref, () => innerRef.current, [])

  useEffect(() => {
    if (!marker || !clusterer) {
      return
    }

    clusterer.addMarker(marker)
  }, [clusterer, marker])

  const handleClick = () => {
    innerRef.current?.toggle()
  }

  const handleOpen = () => {
    onOpen?.(innerRef as MutableRefObject<google.maps.InfoWindow | null>)
  }

  const handleClose = () => {
    innerRef.current?.close()
  }

  return (
    <>
      <AdvancedMarker position={{ lat: agent.lat, lng: agent.lng }} ref={markerRef} onClick={handleClick} />

      <InfoWindow anchor={marker} onClose={onClose} onOpen={handleOpen} ref={infoWindowRef}>
        <InfoWindowContent agent={agent} onClose={handleClose} />
      </InfoWindow>
    </>
  )
})

AgentMarker.displayName = 'AgentMarker'
