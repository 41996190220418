import { useMap } from '@vis.gl/react-google-maps'
import { forwardRef, MutableRefObject, useEffect, useImperativeHandle, useRef } from 'react'
import { createPortal } from 'react-dom'

export type InfoWindowProps = {
  anchor: google.maps.marker.AdvancedMarkerElement | null
  children: React.ReactNode
  onClose?: () => void
  onOpen?: () => void
}

const createInfoWindow = (containerRef: MutableRefObject<HTMLDivElement>) =>
  new google.maps.InfoWindow({ content: containerRef.current, headerDisabled: true })

export const InfoWindow = forwardRef(({ anchor, children, onClose, onOpen }: InfoWindowProps, ref) => {
  const containerRef = useRef(document.createElement('div'))
  const infoWindowRef = useRef(createInfoWindow(containerRef))

  const map = useMap()

  useImperativeHandle(
    ref,
    () => ({
      close() {
        infoWindowRef.current?.close()
      },
      open() {
        infoWindowRef.current?.open({ map, anchor })
      },
      toggle() {
        if (infoWindowRef.current?.isOpen) {
          infoWindowRef.current?.close()
        } else {
          infoWindowRef.current?.open({ map, anchor })
        }
      },
    }),
    [anchor, map]
  )

  useEffect(() => {
    const listenerClose = infoWindowRef.current.addListener('close', handleClose)

    const listenerOpen = infoWindowRef.current.addListener('domready', handleOpen)

    return () => {
      listenerClose.remove()
      listenerOpen.remove()
    }
  }, [])

  const handleClose = () => {
    onClose?.()
  }

  const handleOpen = () => {
    onOpen?.()
  }

  return createPortal(children, containerRef.current)
})

InfoWindow.displayName = 'InfoWindow'
