import { breakpoints, g } from '@pretto/bricks/components/layout'
import BaseImage from '@pretto/bricks/website/shared/components/Image'

import styled from 'styled-components'

export const InfoWindowContentContainer = styled.div`
  background-color: white;
  border-radius: ${g(1)};
  width: 100%;
  white-space: normal;

  @media screen and (min-width: ${breakpoints.tablet}) {
    max-width: ${g(45)};
  }
`

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
`

export const Name = styled.div`
  ${({ theme }) => theme.typos.heading5};
  margin-bottom: ${g(1, -4)};
`

export const CloseButton = styled.svg`
  height: ${g(2)};
  width: ${g(2)};
  cursor: pointer;
`

export const Description = styled.div`
  ${({ theme }) => theme.typos.body4};
`

export const Email = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.typos.body4Underline};
  cursor: pointer;
`

export const BottomSection = styled.div`
  display: flex;
  margin-top: ${g(2)};
  gap: ${g(2)};
`

export const Photo = styled(BaseImage).attrs({ options: { height: '120', width: '96', crop: 'auto' } })`
  border-radius: ${g(1)};
`

export const ContactSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${g(1)};
`

export const Number = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.typos.body4Bold};
  cursor: pointer;
  margin-right: auto;
`

export const Address = styled.div`
  ${({ theme }) => theme.typos.caption};
`

export const Website = styled.a`
  text-decoration: none;
  ${({ theme }) => theme.typos.captionUnderline};
  cursor: pointer;
  margin-right: auto;
`

export const SocialSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${g(1, 4)};
`

export const PictoContainer = styled.a`
  text-decoration: none;
  cursor: pointer;
`

export const SocialPicto = styled.div`
  height: ${g(3)};
  width: ${g(3)};
`
