import {
  Illustration,
  InputComponent,
  SimulatorRowInputsProps,
} from '@pretto/website/src/templates/blocs/components/simulatorRow/SimulatorRow.types'

import { createElement, FC, Fragment } from 'react'

import * as S from './SimulatorRowInputs.styles'

const INPUT_COMPONENT = {
  [InputComponent.DATE]: S.Date,
  [InputComponent.RADIO]: S.Radio,
  [InputComponent.SLIDER]: S.Slider,
}
const ILLUSTRATION = {
  [Illustration.COUPLE]: <S.CoupleIllustration />,
  [Illustration.HOUSE]: <S.HouseIllustration />,
  [Illustration.SATISFIED]: <S.SatisfiedIllustration />,
  [Illustration.SINGLE]: <S.WomanSmilingIllustration />,
}

export const SimulatorRowInputs: FC<SimulatorRowInputsProps> = ({ inputBlocks, onClickComplete, ...props }) => (
  <S.SimulatorRowInputs {...props}>
    {inputBlocks.map(({ illustration, title, inputs }, i) => {
      const isFirst = i === 0
      const isLast = i === inputBlocks.length - 1

      return (
        <Fragment key={title}>
          {!isFirst && <S.Divider />}
          <S.InputSection>
            <S.InputSectionTitle>
              <S.InputSectionIllustration>{ILLUSTRATION[illustration]}</S.InputSectionIllustration>
              {title}
            </S.InputSectionTitle>

            <S.InputCard>
              {inputs.map(({ type, props }, i) => createElement(INPUT_COMPONENT[type], { key: i, ...props }))}

              {isLast && (
                <S.MoreInformation href="https://app.pretto.fr/" onClick={onClickComplete}>
                  Je complète mon profil
                </S.MoreInformation>
              )}
            </S.InputCard>
          </S.InputSection>
        </Fragment>
      )
    })}
  </S.SimulatorRowInputs>
)
