import { SimulatorRow } from '@pretto/website/src/templates/blocs/components/simulatorRow/SimulatorRow'
import {
  SimulatorType,
  TitleTags,
} from '@pretto/website/src/templates/blocs/components/simulatorRow/SimulatorRow.types'
import { useSimulator } from '@pretto/website/src/templates/simulateurAvance/lib/useSimulator'
import { useTracking } from '@pretto/website/src/utilities/tracking'

import React from 'react'

interface SimulatorRowContainerProps {
  simulatorTitle?: string
  simulatorTitleTag?: TitleTags
  simulatorType: SimulatorType
}

export const SimulatorRowContainer: React.FC<SimulatorRowContainerProps> = ({
  simulatorTitle,
  simulatorTitleTag,
  simulatorType,
}) => {
  const trackAction = useTracking()

  const trackButtonClick = (location: string) => {
    trackAction('Calculator CTA clicked', { calculator_v2_cta: location })
  }
  const { errorFlag, isGraphActive, graphProps, inputBlocks, resultProps } = useSimulator(simulatorType, {
    trackButtonClick,
  })

  const handleClickErrorButton = () => {
    trackButtonClick(`error_${errorFlag}`)
  }

  const handleClickProfileButton = () => {
    trackButtonClick('profile')
  }

  const handleClickDescriptionButton = () => {
    trackButtonClick('description')
  }

  const errorProps = {
    flag: errorFlag,
    onClickButton: handleClickErrorButton,
  }

  const isError = !!errorFlag

  const simulatorProps = {
    errorProps,
    graphProps,
    inputBlocks,
    isGraphActive,
    isError,
    onClickComplete: handleClickProfileButton,
    onClickDescriptionButton: handleClickDescriptionButton,
    resultProps,
    title: simulatorTitle,
    titleTag: simulatorTitleTag,
    type: simulatorType,
  }

  return <SimulatorRow {...simulatorProps} />
}
