import ButtonInline from '@pretto/bricks/components/buttons/ButtonInline'
import Illustration from '@pretto/bricks/components/images/Illustration'
import { breakpoints, g } from '@pretto/bricks/components/layout'
import * as typo from '@pretto/bricks/core/typography'
import { Date as DateComponent } from '@pretto/bricks/website/calculator2/components/Date'
import { Radio as RadioComponent } from '@pretto/bricks/website/calculator2/components/Radio'
import { Slider as SliderComponent } from '@pretto/bricks/website/calculator2/components/Slider'

import styled, { css } from 'styled-components'

const CARD_STYLE = css`
  border: 1px solid ${({ theme }) => theme.colors.neutral3};
  border-radius: ${g(1)};
  padding: ${g(3)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-left: ${g(4)};
    padding-right: ${g(4)};
  }
`

export const SimulatorRowInputs = styled.div`
  ${CARD_STYLE}

  @media screen and (min-width: ${breakpoints.tablet}) {
    border: initial;
    border-radius: initial;
    padding: initial;
    margin-bottom: ${g(3)};
    grid-column: 2 / -2;
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    grid-column: 2 / span 5;
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding-right: ${g(7)};
  }
`
export const Divider = styled.div`
  margin: ${g(3)} 0;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral3};

  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`
export const InputSection = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${Divider} + & {
      margin-top: ${g(5)};
    }
  }
`
export const InputSectionTitle = styled.div`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${typo.heading32};
    display: block;
    margin-bottom: ${g(2)};
    display: flex;
    align-items: center;
  }
`
export const InputSectionIllustration = styled.div`
  height: ${g(7)};
  width: ${g(7)};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral4};
  margin-right: ${g(2)};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`
export const HouseIllustration = styled(Illustration).attrs({
  height: 48,
  path: 'v1637684214/zen/static-assets/illustrations/660x660/house1.svg',
  width: 45,
})`
  width: 45px;
  height: 48px;
`
export const WomanSmilingIllustration = styled(Illustration).attrs({
  height: 56,
  path: 'v1637685718/zen/static-assets/illustrations/660x660/frame-woman-smiling.svg',
  width: 56,
})`
  transform: translateY(${g(1)});
  width: ${g(7)};
  height: ${g(7)};
`
export const SatisfiedIllustration = styled(WomanSmilingIllustration).attrs({
  path: 'v1629988872/bricks/static-assets/illustrations/Satisfied_transparent.svg',
})``
export const CoupleIllustration = styled(Illustration).attrs({
  height: 43,
  path: 'v1622808009/bricks/static-assets/illustrations/Couple.svg',
  width: 52,
})`
  width: 52px;
  height: 43px;
  align-self: flex-end;
  transform: translateY(2px);
`
export const InputCard = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    ${CARD_STYLE}
  }
`
export const MoreInformation = styled(ButtonInline).attrs({ isSecondary: true })`
  margin-top: ${g(5)};
`

const InputComponent = styled.div`
  & + & {
    margin-top: ${g(3)};
  }
`

export const Slider = styled(InputComponent).attrs({ as: SliderComponent })``
export const Radio = styled(InputComponent).attrs({ as: RadioComponent })``
export const Date = styled(InputComponent).attrs({ as: DateComponent })``
