import { FormatedAgentType } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { useMap } from '@vis.gl/react-google-maps'
import { MutableRefObject, useMemo, useRef } from 'react'

import { AgentMarker } from './AgentMarker'

type ClusteredAgentMarkersProps = {
  agents: FormatedAgentType[]
}

export type AgentRefType = {
  current: google.maps.InfoWindow | null
}

export const ClusteredAgentMarkers = ({ agents }: ClusteredAgentMarkersProps) => {
  const map = useMap()

  const openedAgentRef: MutableRefObject<google.maps.InfoWindow | null> = useRef(null)

  const clusterer = useMemo<MarkerClusterer | null>(() => {
    if (!map) {
      return null
    }

    return new MarkerClusterer({ map })
  }, [map])

  const handleOpen = (agentRef: MutableRefObject<google.maps.InfoWindow | null>) => {
    if (openedAgentRef.current && openedAgentRef.current !== agentRef.current) {
      openedAgentRef.current?.close()
    }

    openedAgentRef.current = agentRef.current
  }

  return (
    <>
      {agents.map(agent => (
        <AgentMarker agent={agent} clusterer={clusterer} key={agent.key} onOpen={handleOpen} />
      ))}
    </>
  )
}
