import { breakpoints, g } from '@pretto/bricks/components/layout'
import { memo } from 'react'
import styled from 'styled-components'

const Row = styled.div`
  margin-bottom: ${g(8)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(15)};
  }
`

export default memo(Row)
