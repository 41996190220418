import { Grid } from '@pretto/bricks/components/layout/Grid'

import { PostContentContext } from '@pretto/website/src/postTypes/context/PostContent'

import PropTypes from 'prop-types'
import { useContext } from 'react'

import * as S from './Blocs.styles'

export const Blocs = ({ menuComponent, rowsComponent }) => {
  const { contentComponent } = useContext(PostContentContext)

  return (
    <S.Page>
      {menuComponent}
      {rowsComponent}

      {contentComponent && (
        <Grid>
          <S.Content>{contentComponent}</S.Content>
        </Grid>
      )}
    </S.Page>
  )
}

Blocs.propTypes = {
  menuComponent: PropTypes.node,
  rowsComponent: PropTypes.arrayOf(PropTypes.node).isRequired,
}
