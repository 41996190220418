import { FacebookSmall, InstagramSmall, LinkedinSmall, TimesBold } from '@pretto/picto'
import { FormatedAgentType } from '@pretto/website/src/templates/blocs/rows/rowPrettoGalaxie/config/agents'

import * as S from './InfosWindowContent.styles'

type AgentMapProps = {
  agent: FormatedAgentType
  onClose: () => void
}

export const InfoWindowContent = ({ agent, onClose, ...props }: AgentMapProps) => (
  <S.InfoWindowContentContainer {...props}>
    <S.FirstRow>
      <S.Name onClick={onClose}>{agent.name}</S.Name>
      <S.CloseButton as={TimesBold} onClick={onClose} />
    </S.FirstRow>

    <S.Description>{agent.desc}</S.Description>
    <S.Email href={`mailto:${agent.email}`}>{agent.email}</S.Email>

    <S.BottomSection>
      <S.Photo path={agent.img}></S.Photo>

      <S.ContactSection>
        {agent.tel && <S.Number href={`tel:${agent.tel}`}>{agent.tel}</S.Number>}
        {agent.address && <S.Address>{agent.address}</S.Address>}
        {agent.website && (
          <S.Website href={agent.website} target="_blank">
            {agent.website}
          </S.Website>
        )}

        <S.SocialSection>
          {agent.facebook && (
            <S.PictoContainer href={agent.facebook}>
              <S.SocialPicto as={FacebookSmall} />
            </S.PictoContainer>
          )}
          {agent.linkedin && (
            <S.PictoContainer href={agent.linkedin}>
              <S.SocialPicto as={LinkedinSmall} />
            </S.PictoContainer>
          )}
          {agent.instagram && (
            <S.PictoContainer href={agent.instagram}>
              <S.SocialPicto as={InstagramSmall} />
            </S.PictoContainer>
          )}
        </S.SocialSection>
      </S.ContactSection>
    </S.BottomSection>
  </S.InfoWindowContentContainer>
)
